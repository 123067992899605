import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PostTemplateDetails from '../components/PostTemplateDetails'

class PostTemplate extends React.Component {
  render() {
    const { title, subtitle } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const { title: postTitle, description: postDescription } = post.frontmatter
    const description = postDescription !== null ? postDescription : subtitle

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${postTitle} - ${title}`}</title>
            <meta name="description" content={description} />
          </Helmet>
          <Header {...this.props} />
          <section className="section">
            <PostTemplateDetails {...this.props} />
          </section>
          <section className="section ctoa">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="section-title text-center">
                    <h4 className="title mb-4">Get the Modern <br/> Email Workflow Testing Tool</h4>
                    <p className="text-muted para-desc mx-auto">Get unlimited disposable emails instantly and set up automation.</p>
                    <ul className="list-unstyled text-center mb-0 mt-5">
                      <li className="list-inline-item">
                        <a href="https://api.mail7.io/login" className="btn btn-primary mb-2">Get Your FREE Pro Plan</a>
                        </li>
                      <li className="list-inline-item mx-2">or</li>
                      <li className="list-inline-item">
                        <a href="https://console.mail7.io/admin/inbox/inbox" className="text-primary mb-2">Go to Public Inbox</a>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer {...this.props} />

      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        description
        author
      }
    }
  }
`
