import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import './style.scss'

class PostTemplateDetails extends React.Component {
  render() {
    const {subtitle} = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const tags = post.fields.tagSlugs

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tags &&
            tags.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )


    return (
      <div>
        <div className="post-single">
          <div className="post-single__inner">
            <h1>{post.frontmatter.title}</h1>
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html.replaceAll('/static/','/blog/static/') }}
            />
            <div>
              <em>
                Published {moment(post.frontmatter.date).format('D MMM YYYY')}
              </em>
            </div>
          
          <div>
            {tagsBlock}
            <hr />
            <p className="post-single__footer-text">
              <div class="author-avatar">
                <img src={post.frontmatter.author[2]} title={post.frontmatter.author[0]} />
              </div>
              <header>
                <h2 class="g1-gamma g1-gamma-1st author-title">Written by {post.frontmatter.author[0]}</h2>
							</header>
              <div class="author-bio" dangerouslySetInnerHTML={{ __html: post.frontmatter.author[1] }}>
              </div>
            </p>
          </div>        
        <hr />
        <div id="s9comments"></div>
        </div>
      </div>
      </div>
    )
  }
}

export default PostTemplateDetails
